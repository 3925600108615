
import EthereumSession from './eth-session';
import IgnisElitePhaseII from './IgnisElitePhaseII-abi.json';

const CONFIG = {
    chain:     EthereumSession.COMMON_CHAINS[1],
    infuraId:  import.meta.env.VITE_INFURA_ID,
    network:  'mainnet',

    address:   import.meta.env.VITE_PHASE2_CONTRACT_ADDRESS,
    abi:       IgnisElitePhaseII,
};

CONFIG.networkUrl = `https://${CONFIG.network}.infura.io/v3/${CONFIG.infuraId}`;

export default CONFIG;